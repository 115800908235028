import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  useGetAccountApikeyQuery,
  useGetAccountProfileQuery,
  useGetAccountApikeyBalanceMutation,
} from "../../services/accountApi";
import {
  addAccountApikey,
  setAccountProfile,
  setAccountBalance,
} from "../../slices/accountSlice";
import styled from "styled-components";
import { css } from "styled-components";
import Aside from "./Aside";
import Header from "./Header";
import { useGetDropdownQuery } from "../../services/systemApi";
import {
  setDropdown,
  setBchusdtPrice,
  setBnbusdtPrice,
  setBtcusdtPrice,
  setCakeusdtPrice,
  setEthusdtPrice,
  setIotausdtPrice,
  setNeousdtPrice,
  setEosusdtPrice,
  setEtcusdtPrice,
  setSolusdtPrice,
} from "../../slices/systemSlice";

import {
  makeBinanceWebsocketAuthRequest,
  makeBybitWebsocketAuthMessage,
  setTermsOfUser,
} from "../../services/common";

import "../../../App.css";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { ChekcUserAgent } from "../../utils";

// avapoint
import Deposit from "../AvaPoint/Deposit";
import Withdraw from "../AvaPoint/Withdraw";
import History from "../AvaPoint/History";
import { useState } from "react";

import Onboarding from "../Onboarding/Onboarding";
import { getOnboarding, getTermsOfUser } from "../../services/common";
import Loading from "../Loading/Loading";
import TermsOfUse from "../TermsOfUse/TermsOfUse";

// #region styled
const Wrapper = styled.div`
  font-family: Poppins;
  display: flex;
  background-color: #151944;
  min-height: 100vh;
  height: 100%;
  position: relative;
  justify-content: flex-end;
`;

const AsideContainer = styled.div`
  width: clamp(175px, 10.4vw, 200px);
  min-height: 100vh;
  height: 100%;
  background-color: #22254a;
  position: fixed;
  transition: all 0.3s;
  left: 0;
  z-index: 3;

  ${RWD_LG(css`
    width: 300px;
    left: ${(props) => (props.open ? "0" : "-300px")};
  `)};

  ${RWD_SM(css`
    width: 200px;
    left: ${(props) => (props.open ? "0" : "-200px")};
  `)};
`;

const HeaderContainer = styled.div`
  height: 80px;

  /* height: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  width: calc(100% - clamp(175px, 10.4vw, 200px));
  /* padding: 0 3.125vw; */
  background-color: #151944;

  ${RWD_LG(css`
    width: 100%;
    z-index: 2;
    height: 100px;
  `)};

  /* ${RWD_SM(css`
    height: 100px;
  `)}; */
`;

const OutletContainer = styled.div`
  width: calc(100% - clamp(175px, 10.4vw, 200px));
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  padding: 2vw 3.125vw;

  background-color: #151944;

  ${RWD_LG(css`
    margin-top: 100px;
    min-height: calc(100vh - 100px);
    /* padding: calc(80px + 45px + 20px) 3.125vw 20px 3.125vw; */
    width: 100%;
  `)};

  ${RWD_SM(css`
    padding: 3.125vw;
    /* padding: calc(60px + 45px + 20px) 3.125vw 20px 3.125vw; */
  `)};
`;
// #endregion

const Layout = () => {
  const dispatch = useDispatch();
  const {
    data: accountApikeyResponse,
    isSuccess: isGetAccountApikeySuccess,
    isLoading: isGetAccountApikeyLoading,
    isFetching: isGetAccountApikeyFetching,
    refetch: getAccountApiKeyRefetch,
  } = useGetAccountApikeyQuery();

  const {
    data: accountProfileRespnse,
    isSuccess: isGetAccountProfileSuccess,
    isLoading: isGetAccountProfileLoading,
    isFetching: isGetAccountProfileFetching,
    refetch: getAccountProfileRefetch,
  } = useGetAccountProfileQuery();

  const {
    data: dropdownResponse,
    isSuccess: isGetDropdownSuccess,
    isFetching: isGetDropdownFetching,
  } = useGetDropdownQuery();

  // const [
  //   _getBalance,
  //   {
  //     data: getAccountBalanceResponse,
  //     isSuccess: isGetAccountBalanceIsSuccess,
  //     error: isGetAccountBalanceError,
  //     isError: isGetAccountBalanceIsError,
  //   },
  // ] = useGetAccountApikeyBalanceMutation();

  // #region websocket
  useEffect(() => {
    // current btc price (binance)
    // const btcprice = new WebSocket(
    //   "wss://stream.binance.com:9443/ws/btcusdt@ticker"
    // );
    // btcprice.onmessage = (event) => {
    //   const data = JSON.parse(event.data);
    //   dispatch(setBtcusdtPrice(data.c));
    // };

    // BCH,BNB,BTC,CAKE,ETH,IOTA,NEO,USDT

    let bchcprice;
    let bnbprice;
    let btcprice;
    let cakeprice;
    let ethprice;
    let iotaprice;
    let neoprice;
    let eosprice;
    let etcprice;
    let solprice;

    const cryptos = [
      { crypto: "bch", fun: setBchusdtPrice, object: bchcprice },
      { crypto: "bnb", fun: setBnbusdtPrice, object: bnbprice },
      { crypto: "btc", fun: setBtcusdtPrice, object: btcprice },
      { crypto: "cake", fun: setCakeusdtPrice, object: cakeprice },
      { crypto: "eth", fun: setEthusdtPrice, object: ethprice },
      { crypto: "iota", fun: setIotausdtPrice, object: iotaprice },
      { crypto: "neo", fun: setNeousdtPrice, object: neoprice },
      { crypto: "eos", fun: setEosusdtPrice, object: eosprice },
      { crypto: "etc", fun: setEtcusdtPrice, object: etcprice },
      { crypto: "sol", fun: setSolusdtPrice, object: solprice },
    ];

    cryptos.map((item) => {
      item.object = new WebSocket(
        `wss://stream.binance.com:9443/ws/${item.crypto}usdt@ticker`
      );
      item.object.onmessage = () => {
        let data = JSON.parse(event.data);
        dispatch(item.fun(data.c));
      };
    });

    return () => {
      console.log(cryptos);

      cryptos.forEach((data) => data.object.close());
    };
  }, []);
  // #endregion

  useEffect(() => {
    if (!isGetAccountProfileSuccess || isGetAccountProfileFetching) return;
    dispatch(setAccountProfile(accountProfileRespnse));
  }, [isGetAccountProfileSuccess, isGetAccountProfileFetching]);

  useEffect(() => {
    if (!isGetAccountApikeySuccess || isGetAccountApikeyFetching) return;
    dispatch(addAccountApikey(accountApikeyResponse));

    // binance
    let binanceWallet;
    // const hasBinanceKey = accountApikeyResponse?.Apikeys?.filter(({ ExchangeId }) => ExchangeId === 1);
    // if (hasBinanceKey.length > 0) {
    //   const binanceKey = hasBinanceKey[0];
    //   try {
    //     const url = 'wss://ws-api.binance.com:443/ws-api/v3';
    //     makeBinanceWebsocketAuthRequest();
    //     // bybitWebSocket = new WebSocket(url);
    //     // bybitWebSocket.onopen = () => {
    //     //   const authMessage = makeBybitWebsocketAuthMessage({
    //     //     apiKey: binanceKey.ApiKey,
    //     //     SecretKey: binanceKey.SecretKey,
    //     //   });
    //     //   bybitWebSocket.send(authMessage);
    //     // }
    //     // bybitWebSocket.onmessage = (event) => {
    //     //   const message = JSON.parse(event.data);
    //     //   console.log(message);

    //     //   if (message.success && message.op === 'auth') {
    //     //     bybitWebSocket.send(JSON.stringify({
    //     //       op: 'subscribe',
    //     //       args: ['wallet'],
    //     //     }));
    //     //   }

    //     //   if (message?.topic === 'wallet') {
    //     //     console.log('Received wallet update:', message.data);
    //     //   }
    //     // };
    //   }
    //   catch (e) {
    //     console.error(e);
    //   }
    // }

    // bybit
    let bybitWebSocket;
    const hasBybitKey = accountApikeyResponse?.Apikeys?.filter(
      ({ ExchangeId }) => ExchangeId === 2
    );
    if (hasBybitKey.length > 0) {
      const bybitKey = hasBybitKey[0];
      try {
        const url = "wss://stream-testnet.bybit.com/v5/private";
        bybitWebSocket = new WebSocket(url);
        bybitWebSocket.onopen = () => {
          const authMessage = makeBybitWebsocketAuthMessage({
            ApiKey: bybitKey.ApiKey,
            SecretKey: bybitKey.SecretKey,
          });
          bybitWebSocket.send(authMessage);
        };
        bybitWebSocket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          // console.log(message);

          if (message.success && message.op === "auth") {
            bybitWebSocket.send(
              JSON.stringify({
                op: "subscribe",
                args: ["wallet"],
              })
            );
          }

          if (message?.topic === "wallet") {
            console.log("Received wallet update:", message.data);
          }
        };
      } catch (e) {
        console.error(e);
      }
    }

    return () => {
      binanceWallet?.close();
      bybitWebSocket?.close();
    };
  }, [isGetAccountApikeySuccess, isGetAccountApikeyFetching]);

  useEffect(() => {
    if (!isGetDropdownSuccess || isGetDropdownFetching) return;
    dispatch(setDropdown(dropdownResponse));
  }, [isGetDropdownSuccess, isGetDropdownFetching]);

  // avapoint
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [hamIsOpen, setHamIsOpen] = useState(false);

  // onboarding
  const [onboardingIsOpen, setOnboardingIsOpen] = useState(false);
  const [missionStatus, setMissionStatus] = useState([]);

  // terms
  const [termsOfUserIsOpen, setTermsOfUserIsOpen] = useState(false);
  const [termsReadingStatus, setTermsReadingStatus] = useState("");

  useEffect(() => {
    if (isGetAccountProfileSuccess && isGetAccountApikeySuccess) {
      const mission = {
        telegram: accountProfileRespnse?.Profile?.IsTelegramVerified,
        withdrawal: !(
          accountProfileRespnse?.Profile?.Withdrawal === null ||
          accountProfileRespnse?.Profile?.Withdrawal === ""
        ),
        apikey: accountApikeyResponse?.Apikeys.length > 0,
      };

      // 確認是否讀過使用者條款
      setTermsReadingStatus(accountProfileRespnse?.Profile?.IsTermsRead);

      setMissionStatus((missionStatus) => [...missionStatus, { ...mission }]);
    }
  }, [accountApikeyResponse, accountProfileRespnse]);

  useEffect(() => {
    if (
      (!isGetAccountApikeyLoading || !isGetAccountApikeyLoading) &&
      missionStatus.length > 0
    ) {
      if (Object.values(missionStatus?.[0])?.every((data) => data)) {
        setOnboardingIsOpen(false);
      } else {
        if (getOnboarding() !== "checked" && !termsOfUserIsOpen)
          setOnboardingIsOpen(true);
      }
      // console.log(missionStatus);
    }
  }, [missionStatus, termsOfUserIsOpen]);

  useEffect(() => {
    if (!termsReadingStatus) {
      setTermsOfUserIsOpen(true);
    } else {
      setTermsOfUserIsOpen(false);
      setTermsOfUser("confirmed");
    }
  }, [termsReadingStatus]);

  const location = useLocation();

  // 新增algo 和 mining 時 有沒有立即啟動
  const [algoInit, setAlgoInit] = useState(false);
  const [miningWaiting, setMiningWaiting] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("v2")) {
      document.body.style.overflow = "auto";
      document.body.style.overflowX = "hidden";
    }

    // 離開algo頁面後 恢復成false (頁面的tab)
    if (location.pathname !== "/v2/algo") {
      setAlgoInit(false);
    }

    // 離開mining頁面後 恢復成false (頁面的tab)
    if (location.pathname !== "/v2/mining") {
      setMiningWaiting(false);
    }
  }, [location]);

  // screen size function
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    const handleScroll = (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
    };

    window.addEventListener("resize", handleWindowResize);

    window.addEventListener(
      "touchmove",
      (e) => {
        if (onboardingIsOpen) {
          console.log("eeee");
          handleScroll(e);
        }
      },
      { passive: false }
    );

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, [windowSize[0]]);

  const refreshViewHeight = () => {
    const vh = windowSize[1] * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  return (
    <Wrapper>
      {(isGetAccountApikeyLoading || isGetAccountApikeyLoading) && <Loading />}

      <AsideContainer open={hamIsOpen}>
        <Aside
          openDepositFun={setOpenDeposit}
          openWithdrawFun={setOpenWithdraw}
          openHistoryFun={setOpenHistory}
          hamIsOpen={hamIsOpen}
          toggleHamMenu={setHamIsOpen}
        />
      </AsideContainer>
      <HeaderContainer>
        <Header
          openDepositFun={setOpenDeposit}
          openWithdrawFun={setOpenWithdraw}
          openHistoryFun={setOpenHistory}
          hamIsOpen={hamIsOpen}
          toggleHamMenu={setHamIsOpen}
          setAlgoInit={setAlgoInit}
          setMiningWaiting={setMiningWaiting}
        />
      </HeaderContainer>

      <OutletContainer>
        <Outlet
          context={{
            addAlgoStatus: [algoInit, setAlgoInit],
            addMiningStatus: [miningWaiting, setMiningWaiting],
            onboardingStatus: [setOnboardingIsOpen],
            refetchProfile: getAccountProfileRefetch,
          }}
        />
        {onboardingIsOpen && missionStatus.length > 0 && (
          <Onboarding
            setOnboardingIsOpen={setOnboardingIsOpen}
            refetchProfile={getAccountProfileRefetch}
            profileIsFetching={isGetAccountProfileFetching}
            refetchApikey={getAccountApiKeyRefetch}
            apikeyIsFetching={isGetAccountApikeyFetching}
            missionStatus={missionStatus}
          />
        )}

        {termsOfUserIsOpen && termsReadingStatus !== "" && <TermsOfUse />}
      </OutletContainer>
      {openDeposit && <Deposit setOpen={setOpenDeposit} />}
      {openWithdraw && <Withdraw setOpen={setOpenWithdraw} />}
      {openHistory && <History setOpen={setOpenHistory} />}
    </Wrapper>
  );
};

export default Layout;
