import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";
import { useState, Fragment, useEffect } from "react";
import {
  useGetBlockchainNewsDataQuery,
  useGetUserSubscribeBlockChainNewsStatusQuery,
  useUpdateUserSubscribeBlockChainNewsStatusMutation,
} from "../../../../services/datacenterApi";
import { useVerifyTelegramMutation } from "../../../../services/authApi";
import { NewsDateFormat } from "../../../../utils";

import Icons from "../../../../global/icons";
import Lang from "../../../../global/Lang";
import Loading from "../../../../components/Loading/Loading";
import SubscribeModal from "../SubscribeModal";

import { useSelector } from "react-redux";
import {
  selectAccount,
  setAccountProfile,
} from "../../../../slices/accountSlice";
import TelegramVerifyModal from "../TelegramVerifyModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// 多國語言
import { LangString } from "../../../../utils";
import { getLanguage } from "../../../../services/common";
import { ErrorMySwal, SuccessMySwal } from "../../../../global/MySwal";
import { useOutletContext } from "react-router-dom";

// socail buttons
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { ImLink } from "react-icons/im";
import { FaCheckCircle } from "react-icons/fa";


const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    background-color: rgb(30, 34, 64); 
    color: #fff;
    font-size: 16px; 
    border-radius: 8px; 
  }

  .Toastify__toast--success {
    background-color: rgba(30, 34, 64,0.9);
  }

  .Toastify__toast--error {
    background-color: #dc3545;
  }

  .Toastify__progress-bar {
    background-color: #0aecd1;
  }
`;


const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5px;
  background-color: #22254a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
`;

const Title = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  padding-left: 10px;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const TitleIcon = styled.div`
  width: 25px;
  height: 25px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const SubscribeWrapper = styled.div``;

const SubscribeContaniner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 6px 10px;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s;

  color: ${({ $status }) => ($status ? "#fcfcfc" : "rgba(255, 255, 255, 0.5)")};

  &:hover {
    border-color: #0aecd1;
  }
`;

const SubscribeSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  color: rebeccapurple;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;

  &::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const SubscribeCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${SubscribeSlider} {
    background-color: #2196f3;

    justify-content: flex-start;
  }

  &:checked + ${SubscribeSlider}::before {
    transform: translateX(26px);
  }
`;

const SubscribeText = styled.div`
  font-size: clamp(12px, 0.7291vw, 14px);
  letter-spacing: 1.2px;
  white-space: nowrap;
`;

const SubscribeIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${({ $status }) =>
    $status ? "#0aecd1" : "rgba(255, 255, 255, 0.5)"};
    }
  }

  ${RWD_SM(css`
    width: 15px;
    height: 15px;

    svg {
      width: 15px;
      height: 15px;
    }
  `)};
`;

const NoteWrapper = styled.div`
  width: 95%;
  /* background-color: #3a3d5a; */
`;

const NoteContainer = styled.div`
  display: flex;
  padding: 15px 0;
  align-items: flex-start;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
`;

const Note = styled.div`
  width: 95%;
  display: flex;
  gap: 2%;
  justify-content: space-between;

  ${RWD_SM(css`
    gap: 3%;
  `)};
`;

const NoteTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoteEllipsisWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NoteTime = styled.div`
  width: 15%;
  font-size: clamp(14px, 1.041667vw, 20px);
  color: #fcfcfc;
  font-family: Montserrat;
`;

const NoteTitle = styled.div`
  font-size: clamp(22px, 1.25vw, 24px);
  font-weight: 500;
  padding-bottom: 10px;
  color: #fcfcfc;

  ${RWD_SM(css`
    font-size: clamp(18px, 0.465vw, 20px);
  `)};
`;

const NoteContentWrapper = styled.div``;

const NoteContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NoteContentReason = styled.div`
  /* width: 45%; */
`;

const NoteReason = styled.div`
  font-size: min(max(0.9375vw, 15px), 18px);
  color: rgba(255, 255, 255, 0.6);
`;

const NoteCommentWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  ${RWD_SM(css`
    gap: 10px;
  `)};
`;

const NoteComment = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid;
  border-radius: 20px;
  border-color: ${({ $status }) =>
    $status !== "positive"
      ? $status === "negative"
        ? "#f05d2f"
        : "#00c2cb"
      : "#1cac2a"};
  padding: 5px 10px;
`;

const NoteMarket = styled.div`
  font-size: clamp(14px, 0.9375vw, 18px);
`;

const NoteLevel = styled.div`
  font-size: clamp(14px, 0.9375vw, 18px);
`;

const NoteLevelIcon = styled.div`
  display: inline-flex;
  width: clamp(15px, 1.3vw, 25px);
  height: clamp(15px, 1.3vw, 25px);

  svg {
    width: clamp(15px, 1.3vw, 25px);
    height: clamp(15px, 1.3vw, 25px);

    & path {
      fill: ${({ $status }) =>
    $status !== "positive"
      ? $status === "negative"
        ? "#f05d2f"
        : "#00c2cb"
      : "#1cac2a"};
    }
  }
`;

const NoteContentComment = styled.div`
  margin-bottom: 20px;
  /* width: 45%; */
`;

const NoteContentTitle = styled.div`
  margin: 10px 0;
  font-weight: 500;
  font-size: clamp(16px, 1.25vw, 24px);
`;

const NoteContentEllipsis = styled.div`
  color: rgba(255, 255, 255, 0.5);
  text-align: justify;
  font-size: min(max(0.9375vw, 15px), 18px);
`;

const NoteContent = styled.ul`
  color: rgba(255, 255, 255, 0.5);
  padding-bottom: 20px;
  list-style-type: circle;
  list-style-position: inside;
  font-size: min(max(0.9375vw, 15px), 18px);
  text-align: justify;
  flex: 1;
`;

const NoteContentItem = styled.li`
  padding: 5px 0;
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 15px;
  color: #fcfcfc;
`;

const CopyAndOpenIcon = styled.div`
  display:flex;
`;

const CopyIcon = styled.span`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover{
    color:#0aecd1;
  }

`;

const SwitchWrapper = styled.div`
  color: #fcfcfc;
  width: 30px;
  height: 30px;
  border: 0;
  font-size: 10px;
  position: relative;

`;

const SwitchBar = styled.span`
  position: absolute;
  transition: 0.3s;
  background: #fcfcfc;
  border-radius: 1px;
  transform: ${(props) => (props.status ? "rotate(90deg)" : "rotate(0deg)")};

  :first-child {
    top: 25%;
    bottom: 25%;
    width: 10%;
    left: 45%;
  }

  :last-child {
    left: ${(props) => (props.status ? "50%" : "25%")};
    right: ${(props) => (props.status ? "50%" : "25%")};
    height: 10%;
    top: 45%;
  }
`;

const PageWrapper = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${RWD_MD(css`
    justify-content: center;
  `)};
`;

const PageContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ActionPage = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & path {
    transition: all 0.3s;
    stroke: rgba(255, 255, 255, 0.15);
  }

  &:hover {
    & path {
      stroke: #0aecd1;
    }
  }
`;

const MorePage = styled.div`
  padding: 5px 10px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    border-color: #0aecd1;
  }

  ${RWD_MD(css`
    font-size: clamp(12px, 0.833vw, 16px);
  `)};
`;

const LessPage = styled.div`
  padding: 5px 10px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    border-color: #0aecd1;
  }

  ${RWD_MD(css`
    font-size: clamp(12px, 0.833vw, 16px);
  `)};
`;

const Page = styled.div`
  border: 1px solid blue;
  min-width: 30px;
  padding: 5px 10px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: rgba(255, 255, 255, 0.15);
  transition: all 0.3s;
  font-size: 14px;

  border-color: ${({ $status }) =>
    $status ? "#0aecd1" : "rgba(255, 255, 255, 0.15)"};

  &:hover {
    border-color: #0aecd1;
  }

  ${RWD_MD(css`
    font-size: clamp(12px, 0.833vw, 16px);
  `)};
`;

const SocailButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

const SocailButton = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.8);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #0aecd1;
    border: 1px solid #0aecd1;
  }
`;

const NewsAndImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;

  ${RWD_MD(css`
    margin-bottom: 0px;
  `)};
`;

const NewsImgWrapper = styled.div`
  width: 25%;

  ${RWD_MD(css`
    width: 100%;
  `)};

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const News = () => {
  const profileData = useSelector(selectAccount).accountProfile;
  const { refetchProfile } = useOutletContext();

  const [tgIsVerified, setTgIsVerified] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [tgVerifyModalIsOpen, setTgVerifyModalIsOpen] = useState(false);

  useEffect(() => {
    setTgIsVerified(profileData.IsTelegramVerified);
  }, [profileData]);

  const [newsList, setNewsList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageTag, setCurrentPageTag] = useState(1);
  const [totalPageTag, setTotalPageTag] = useState();
  const [totalPage, setTotalPage] = useState();
  const pageSize = 5;

  const [subscribeIsOpen, setSubscribeIsOpen] = useState(false);

  const [pagination, setPagination] = useState([]);
  const [subscribeStatus, setSubscribeStatus] = useState(false);

  const socailButtons = [
    {
      key: "facebook",
      icon: <FaFacebookF />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        "https://www.avairobot.com/AVA"
      )}`,
    },
    {
      key: "link",
      icon: <FaLink />,
    },
    {
      key: "telegram",
      icon: <FaTelegramPlane />,
      link: `https://telegram.me/share/url?url=${encodeURIComponent(
        "https://example.com"
      )}`,
    },
    {
      key: "twitter",
      icon: <FaXTwitter />,
      link: `https://twitter.com/share?url=${encodeURIComponent(
        "https://example.com"
      )}`,
    },
  ];

  const handleShareLink = (link) => {
    window.open(`${link}`, "_blank", "noopener noreferrer");
  };

  const { data, error, isError, isSuccess, isLoading, isFetching } =
    useGetBlockchainNewsDataQuery(activePage);

  const {
    data: subscribeStatusData,
    error: subscribeStatusError,
    isError: subscribeStatusIsError,
    isSuccess: subscribeStatusIsSuccess,
    isLoading: subscribeStatusIsLoading,
  } = useGetUserSubscribeBlockChainNewsStatusQuery();

  const [
    _subscribe,
    {
      data: subscribeData,
      error: subscribeError,
      isError: subscribeIsError,
      isSuccess: subscribeIsSuccess,
      isLoading: subscribeIsLoading,
    },
  ] = useUpdateUserSubscribeBlockChainNewsStatusMutation();

  const [
    _verifyTelegram,
    {
      data: telegramData,
      error: telegramError,
      isSuccess: telegramIsSuccess,
      isError: telegramIsError,
    },
  ] = useVerifyTelegramMutation();

  useEffect(() => {
    if (subscribeStatusIsSuccess) {
      setSubscribeStatus(subscribeStatusData.IsSubscribed);
    }
  }, [subscribeStatusData, subscribeStatusIsError]);

  useEffect(() => {
    if (subscribeIsSuccess) {
      setSubscribeIsOpen(true);
    }
  }, [subscribeData, subscribeIsError]);

  useEffect(() => {
    if (telegramIsSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verification_success",
        "Verification successful"
      );
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          refetchProfile();
          setTgVerifyModalIsOpen(false);
        }
      });
    }

    if (telegramIsError) {
      ErrorMySwal({
        title:
          telegramError?.data?.ResultLanguages[getLanguage()] ||
          telegramError?.data?.Message,
      });
    }
  }, [telegramData, telegramIsError]);

  const handleSubscribe = () => {
    if (tgIsVerified) {
      _subscribe();
    } else {
      setTgVerifyModalIsOpen(true);
    }
  };

  const handleVerifyTelegram = async () => {
    await _verifyTelegram({
      body: {
        Account: profileData?.Account,
        VerifyCode: verifyCode,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setNewsList(
        data.BlockChainNews.map((news, index) => {
          return {
            ...news,
            Comment: news.Comment.split("，").map((item) => {
              const levelType = (value) => {
                switch (value) {
                  case "正面":
                    return "positive";
                  case "中性":
                    return "neutral";
                  case "負面":
                    return "negative";
                }
              };

              return {
                MarketName: item.split("：")[0],
                Type: levelType(item.split("：")[1]),
              };
            }),
            isOpen: index ? false : true, // 第一則新聞開啟
          };
        })
      );

      setTotalCount(data.TotalCount);
    }
  }, [data, isError]);

  useEffect(() => {
    setTotalPageTag(Math.ceil(totalCount / 10 / pageSize));
    setTotalPage(Math.ceil(totalCount / 10));
  }, [totalCount]);

  const handleRenderPagination = () => {
    const pageData = [];
    setCurrentPageTag(Math.ceil(activePage / pageSize));
    const minPage = Math.ceil(activePage / pageSize) * pageSize - pageSize + 1;
    const maxPage = Math.ceil(activePage / pageSize) * pageSize;

    for (let i = 1; i < totalPage + 1; i++) {
      if (i >= minPage && i <= maxPage) {
        pageData.push(i);
      }
    }

    return pageData;
  };

  useEffect(() => {
    if (totalPageTag) {
      window.scrollTo(0, 0);
      setPagination(handleRenderPagination());
    }
  }, [activePage, totalPageTag]);

  const handlePage = (page) => {
    setActivePage(page);
  };

  const handleSlide = (id) => {
    setNewsList(
      newsList.map((data) => {
        if (data.ArticleID === id) {
          data.isOpen = !data.isOpen;
          return data;
        } else {
          return { ...data, isOpen: false };
        }
      })
    );
  };

  const handleNewsEllipsis = (value) => {
    return `${value?.substring(0, 100)}...`;
  };

  const handleMorePage = () => {
    const page = pagination[pageSize - 1] + 1;
    setActivePage(page);
  };

  const handleLessPage = () => {
    const page = pagination[0] - 1;
    setActivePage(page);
  };

  // 複製連結的 toast
  const handleCopy = (e, id, title, comment) => {
    e.stopPropagation();
    const url = `https://www.news.avairobot.com/articles/${id}`;

    // 只取陣列中的第一個項目
    const firstComment = comment[0];

    let typeText = '';
    switch (firstComment.Type) {
      case 'positive':
        typeText = '良好';
        break;
      case 'neutral':
        typeText = '中性';
        break;
      case 'negative':
        typeText = '負面';
        break;
    }

    const parsedComment = `${firstComment.MarketName}：${typeText}\n⋯`;
    const contentToCopy = `今日快訊：\n${title}\n\n分析評級：\n${parsedComment}\n\n查看全文請點擊下方連結\n${url}`;

    navigator.clipboard.writeText(contentToCopy);
    toast.success("複製成功！", {
      icon: <FaCheckCircle style={{ color: "#0aecd1" }} />,
    });
  };

  return (
    <Wrapper>
      {(isFetching || isLoading) && <Loading />}
      <Container>
        <TitleWrapper>
          <TitleIcon>
            <Icons.DataCenter_News />
          </TitleIcon>
          <Title>
            <Lang
              datakey="datacenter_newsdata_newsblog"
              defaultText="AVA News Blog"
            />
          </Title>
          <SubscribeWrapper>
            <SubscribeContaniner
              onClick={handleSubscribe}
              $status={subscribeStatus}
            >
              {/* <SubscribeCheckbox
                onChange={(e) => setSubscribeStatus(e.target.checked)}
                checked={subscribeStatus}
              /> */}
              <SubscribeIcon $status={subscribeStatus}>
                {subscribeStatus ? <Icons.Subscribe /> : <Icons.Unsubscribe />}
              </SubscribeIcon>
              <SubscribeText>
                <Lang datakey="subscribe" defaultText="SUBSCRIBE" />
              </SubscribeText>
            </SubscribeContaniner>
          </SubscribeWrapper>
        </TitleWrapper>

        <NoteWrapper>
          {newsList.map((data) => (
            <NoteContainer
              key={data.ArticleID}
              onClick={() => handleSlide(data.ArticleID)}
            >
              <Note>
                <NoteEllipsisWrapper>
                  <NoteTitleWrapper>
                    <NoteTitle>{data.Title}</NoteTitle>
                    <NoteContentComment>
                      <NoteContentTitle>分析評級</NoteContentTitle>
                      <NoteCommentWrapper>
                        {data.Comment.map((item, index) => (
                          <NoteComment $status={item.Type} key={index}>
                            <NoteLevelIcon $status={item.Type}>
                              {(() => {
                                switch (item.Type) {
                                  case "positive":
                                    return <Icons.Positive />;
                                  case "neutral":
                                    return <Icons.Neutral />;
                                  case "negative":
                                    return <Icons.Negative />;
                                }
                              })()}
                            </NoteLevelIcon>
                            <NoteMarket>{item.MarketName}</NoteMarket>
                            <NoteLevel $status={item.Type}>
                              {(() => {
                                switch (item.Type) {
                                  case "positive":
                                    return "良好";
                                  case "neutral":
                                    return "中性";
                                  case "negative":
                                    return "負面";
                                }
                              })()}
                            </NoteLevel>
                          </NoteComment>
                        ))}
                        {/* <SocailButtonsWrapper>
                          {socailButtons.map((data) => (
                            <SocailButton
                              key={data.key}
                              onClick={() => handleShareLink(data.link)}
                            >
                              {data.icon}
                            </SocailButton>
                          ))}
                        </SocailButtonsWrapper> */}
                      </NoteCommentWrapper>
                    </NoteContentComment>
                    {!data.isOpen && (
                      <NoteContentEllipsis>
                        {handleNewsEllipsis(data.Content)}
                      </NoteContentEllipsis>
                    )}
                  </NoteTitleWrapper>

                  {data.isOpen && (
                    <NoteContentWrapper>
                      <NewsAndImgContainer>
                        {data.ImageUrl ? (
                          <NewsImgWrapper>
                            <img src={data.ImageUrl} alt={data.Title} />
                          </NewsImgWrapper>
                        ) : (
                          <NewsImgWrapper>
                            <img
                              src={"https://picsum.photos/1024/1024?random=1"}
                            />
                          </NewsImgWrapper>
                        )}
                        <NoteContent>{data.Content}</NoteContent>
                      </NewsAndImgContainer>

                      <NoteContentContainer>
                        <NoteContentReason>
                          <NoteContentTitle>市場短評</NoteContentTitle>
                          <NoteReason>{data.CommentReason}</NoteReason>
                        </NoteContentReason>
                      </NoteContentContainer>

                      {/* {data.NewsContent.map((item, index) => (
                      <NoteContent key={index}>
                        {item.itemtitle}
                        {item.itemcontent.map((el, index) => (
                          <NoteContentItem key={index}>{el}</NoteContentItem>
                        ))}
                      </NoteContent>
                    ))} */}
                    </NoteContentWrapper>
                  )}
                </NoteEllipsisWrapper>
                <NoteTime status={data.isOpen}>
                  {NewsDateFormat(data.CreateTime)}
                </NoteTime>
              </Note>
              <CopyAndOpenIcon>
                <CopyIcon
                  onClick={(e) => handleCopy(e, data.ArticleID, data.Title, data.Comment)}>
                  <ImLink />
                </CopyIcon>



                <SwitchWrapper>
                  <SwitchBar status={data.isOpen}></SwitchBar>
                  <SwitchBar status={data.isOpen}></SwitchBar>
                </SwitchWrapper>
              </CopyAndOpenIcon>

            </NoteContainer>
          ))}
        </NoteWrapper>
      </Container>
      <PageWrapper>
        <PageContainer>
          {activePage > 1 && (
            <ActionPage onClick={() => setActivePage(activePage - 1)}>
              <Icons.Arrow_left />
            </ActionPage>
          )}

          {currentPageTag > 1 && (
            <LessPage onClick={handleLessPage}>...</LessPage>
          )}

          {pagination.map((page) => (
            <Page
              key={page}
              $status={page === activePage}
              onClick={() => handlePage(page)}
            >
              {page}
            </Page>
          ))}

          {currentPageTag < totalPageTag && (
            <MorePage onClick={handleMorePage}>...</MorePage>
          )}

          {activePage < totalPage && (
            <ActionPage onClick={() => setActivePage(activePage + 1)}>
              <Icons.Arrow_right />
            </ActionPage>
          )}
        </PageContainer>
      </PageWrapper>
      {subscribeIsOpen && (
        <SubscribeModal
          setSubscribeIsOpen={setSubscribeIsOpen}
          subscribeStatus={subscribeStatus}
        />
      )}

      {tgVerifyModalIsOpen && (
        <TelegramVerifyModal
          setTgVerifyModalIsOpen={setTgVerifyModalIsOpen}
          setVerifyCode={setVerifyCode}
          verifyCode={verifyCode}
          handleVerifyTelegram={handleVerifyTelegram}
        />
      )}

      <StyledToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={true}
      />
    </Wrapper>
  );
};

export default News;