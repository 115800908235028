import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectMining } from "../../../slices/miningSlice";
import { selectSystem } from "../../../slices/systemSlice";

import { useGetMiningPackageQuery } from "../../../services/miningApi";

import { getLanguage } from "../../../services/common";

import Icons from "../../../global/icons";

import Loading from "../../../components/Loading/Loading";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #151944;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  padding: 4vw 2.088vw;
  width: 85vw;
  border-radius: 8px;
  position: relative;

  ${RWD_MD(css`
    width: 95vw;
    padding: 30px 10px 20px 10px;
    height: 80vh;
  `)};
`;

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 0 25px;

  ${RWD_MD(css`
    padding: 0 10px;
  `)};
`;

const Title = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  padding-bottom: 20px;

  /* ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)}; */
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  padding-bottom: 20px;
  color: #fcfcfc;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  `)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const InputEl = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const Label = styled.label`
  padding: 10px 0;
  font-size: min(max(0.833vw, 16px), 18px);
  /* font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)}; */
`;

const PackageWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
  align-self: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;

  ${RWD_LG(css`
    width: 90%;
  `)};

  ${RWD_MD(css`
    width: 100%;
  `)};

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const Package = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* justify-content: space-between; */
  padding: 20px 10px;
  max-width: 400px;
  width: 45%;

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);

  :last-child {
    border: 1px solid #0aecd1;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const PackageNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const PackageName = styled.div`
  padding: 10px 0;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);
  word-wrap: break-word;
`;

const PackageDayWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const PackageDay = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  padding-right: 5px;
`;

const PackageDataWrapper = styled.div`
  width: 50%;
  padding: 20px 0;
`;

const PacakageDataContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PacakageImgDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const PackageImgWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0;
  height: 100%;
`;

const PacakageImg = styled.div`
  width: min(max(10vw, 150px), 200px);
  height: min(max(10vw, 150px), 200px);
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PacakageImgCover = styled.div`
  width: min(max(8vw, 100px), 150px);
  height: min(max(8vw, 100px), 150px);
  background-color: #151944;
  border-radius: 50% 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PackageImgRateText = styled.div``;

const PacakageImgRate = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;

  span {
    font-size: min(max(0.833vw, 16px), 18px);
    font-weight: lighter;
  }
`;

const PackageTarget = styled.div``;
const PackagePercentage = styled.div``;

const PacakageTotalWrapper = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  width: auto;
  min-width: 80%;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const PackageTotalContainer = styled.div`
  display: flex;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 20px;
`;

const PackageUnit = styled.div`
  font-size: min(max(0.833vw, 16px), 18px);
`;

const PacakageTotalName = styled.div`
  font-size: min(max(0.833vw, 16px), 18px);
`;

const PacakageTotal = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  padding-left: 10px;
`;

const ChangeIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 30px;
  height: 30px;
  align-self: center;
  justify-content: center;

  ${RWD_SM(css`
    width: 60px;
    height: 60px;
  `)};

  svg {
    width: 30px;
    height: 30px;

    ${RWD_SM(css`
      transform: rotate(90deg);
    `)};
  }

  & path {
    fill: rgba(255, 255, 255, 0.15);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  & path {
    stroke: #fcfcfc;
  }

  ${RWD_MD(css`
    right: 5px;
    top: -40px;
  `)};
`;

const Preview = ({ previewData, setPreviewModalIsOpen }) => {
  const [packageData, setPackageData] = useState([]);
  const [originalPackageData, setOriginalPackageData] = useState([]);
  const [transferPackageData, setTransferPackageData] = useState([]);
  const [total, setTotal] = useState("");

  // websocket
  const bchusdt = useSelector(selectSystem).bchusdt;
  const bnbusdt = useSelector(selectSystem).bnbusdt;
  const btcusdt = useSelector(selectSystem).btcusdt;
  const cakeusdt = useSelector(selectSystem).cakeusdt;
  const ethusdt = useSelector(selectSystem).ethusdt;
  const iotausdt = useSelector(selectSystem).iotausdt;
  const neousdt = useSelector(selectSystem).neousdt;
  const eosusdt = useSelector(selectSystem).eosusdt;
  const etcusdt = useSelector(selectSystem).etcusdt;
  const solusdt = useSelector(selectSystem).solusdt;

  const transferUsdt = (target, value) => {
    switch (target) {
      case "BCH":
        return value * bchusdt;
      case "BNB":
        return value * bnbusdt;
      case "BTC":
        return value * btcusdt;
      case "CAKE":
        return value * cakeusdt;
      case "ETH":
        return value * ethusdt;
      case "IOTA":
        return value * iotausdt;
      case "NEO":
        return value * neousdt;
      case "EOS":
        return value * eosusdt;
      case "ETC":
        return value * etcusdt;
      case "SOL":
        return value * solusdt;
      case "USDT":
        return value * 1;
      default:
        if (target?.includes("USDT")) return value * 1;
        else return value * 1;
    }
  };

  const {
    data: miningPackageResponse,
    isSuccess: miningPackageResponseIsSuccess,
    isError: miningPackageResponseIsError,
    error: miningPackageResponseError,
    isLoading: miningPackageResponseIsLoading,
  } = useGetMiningPackageQuery();

  useEffect(() => {
    if (miningPackageResponseIsSuccess) {
      setPackageData(miningPackageResponse?.Packages);
    }
  }, [miningPackageResponse, miningPackageResponseIsError]);

  useEffect(() => {
    setTotal(
      previewData[0]?.PeriodItems.map((item) =>
        transferUsdt(item.Target, item.CurrentVolume)
      ).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );
  }, [previewData]);

  useEffect(() => {
    console.log(packageData);

    setOriginalPackageData(
      packageData.filter(
        (data) => data.PackageId === previewData?.[0]?.PackageId
      )
    );

    setTransferPackageData(
      packageData.filter(
        (data) => data.PackageId === previewData?.[0]?.TransferToPackageId
      )
    );
  }, [packageData]);

  const backgroundColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  const BackgroundColor = (data) => {
    const list = data?.map((el) => el.Proportion * 100);
    const percentage = list?.map((item, index, array) => {
      let sub = array.slice(0, index + 1);
      let sum = sub.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    });

    return data?.map((item, index) => {
      if (data.length > 1) {
        return `${backgroundColor[index]} ${percentage[index - 1] ?? 0}% ${
          percentage[index]
        }%`;
      } else {
        return `${backgroundColor[index]} 0 ${percentage[index]}%`;
      }
    });
  };

  console.log(previewData);

  return (
    <Wrapper>
      {(previewData.length <= 0 ||
        miningPackageResponseIsLoading ||
        originalPackageData.length <= 0 ||
        transferPackageData.length <= 0) && <Loading />}
      <Container>
        <CloseIcon onClick={() => setPreviewModalIsOpen(false)}>
          <Icons.Close />
        </CloseIcon>
        <ScrollContainer>
          <Title>
            <Lang
              datakey="mining_package_info"
              defaultText="Mining Package Information"
            />
          </Title>
          <InputWrapper>
            <InputContainer>
              <Label>
                <Lang
                  datakey="mining_package_name"
                  defaultText="Mining Package Name"
                />
              </Label>
              <InputEl
                disabled
                value={
                  previewData?.[0]?.Name
                    ? previewData?.[0]?.Name
                    : previewData?.[0]?.PackageName
                }
              />
            </InputContainer>

            <InputContainer>
              <Label>
                <Lang datakey="deposit_amount" defaultText="Deposit Amount" />
              </Label>
              <InputEl disabled value={previewData?.[0]?.Deposit} />
            </InputContainer>
          </InputWrapper>
          <Title>Transfer Result Review</Title>

          <PackageWrapper>
            <PackageContainer>
              <Package>
                <PackageNameWrapper>
                  <PackageName>{previewData?.[0]?.PackageName}</PackageName>
                </PackageNameWrapper>
                <PackageDayWrapper>
                  <PackageDay>
                    {originalPackageData?.[0]?.PeriodDays}
                  </PackageDay>
                  <PackageUnit> days / period</PackageUnit>
                </PackageDayWrapper>
                <PacakageImgDataWrapper>
                  <PackageImgWrapper>
                    <PacakageImg
                      style={{
                        background: `conic-gradient(${BackgroundColor(
                          originalPackageData?.[0]?.Components
                        )}) `,
                      }}
                    >
                      <PacakageImgCover>
                        <PackageImgRateText>APY</PackageImgRateText>
                        <PacakageImgRate>
                          {`${(originalPackageData?.[0]?.Rate * 100).toFixed(
                            2
                          )}`}
                          <span> %</span>
                        </PacakageImgRate>
                      </PacakageImgCover>
                    </PacakageImg>
                  </PackageImgWrapper>
                  {previewData?.[0]?.PeriodItems.length == 0 ? (
                    <PackageDataWrapper>
                      {originalPackageData?.[0]?.Components.map(
                        (data, index) => (
                          <PacakageDataContainer
                            style={{ color: backgroundColor[index] }}
                          >
                            <PackageTarget>{data.Asset}</PackageTarget>
                            <PackagePercentage>
                              {Number(0).toFixed(2)}
                            </PackagePercentage>
                          </PacakageDataContainer>
                        )
                      )}
                    </PackageDataWrapper>
                  ) : (
                    <PackageDataWrapper>
                      {previewData?.[0]?.PeriodItems.map((data, index) => (
                        <PacakageDataContainer
                          style={{ color: backgroundColor[index] }}
                        >
                          <PackageTarget>{data.Target}</PackageTarget>
                          <PackagePercentage>
                            {data.CurrentVolume?.toFixed(2)}
                          </PackagePercentage>
                        </PacakageDataContainer>
                      ))}
                    </PackageDataWrapper>
                  )}
                </PacakageImgDataWrapper>

                <PacakageTotalWrapper>
                  <PackageTotalContainer>
                    <PacakageTotalName>Total</PacakageTotalName>
                    <PacakageTotal>{Number(total)?.toFixed(2)}</PacakageTotal>
                  </PackageTotalContainer>
                </PacakageTotalWrapper>
              </Package>
              <ChangeIconWrapper>
                <Icons.Change />
              </ChangeIconWrapper>
              <Package>
                <PackageNameWrapper>
                  <PackageName>{transferPackageData?.[0]?.Name}</PackageName>
                </PackageNameWrapper>
                <PackageDayWrapper>
                  <PackageDay>
                    {transferPackageData?.[0]?.PeriodDays}
                  </PackageDay>
                  <PackageUnit> days / period</PackageUnit>
                </PackageDayWrapper>
                <PacakageImgDataWrapper>
                  <PackageImgWrapper>
                    <PacakageImg
                      style={{
                        background: `conic-gradient(${BackgroundColor(
                          transferPackageData?.[0]?.Components
                        )}) `,
                      }}
                    >
                      <PacakageImgCover>
                        <PackageImgRateText>APY</PackageImgRateText>
                        <PacakageImgRate>
                          {`${(originalPackageData?.[0]?.Rate * 100).toFixed(
                            2
                          )}`}
                          <span> %</span>
                        </PacakageImgRate>
                      </PacakageImgCover>
                    </PacakageImg>
                  </PackageImgWrapper>
                  <PackageDataWrapper>
                    {transferPackageData?.[0]?.Components.map((data, index) => (
                      <PacakageDataContainer
                        style={{ color: backgroundColor[index] }}
                      >
                        <PackageTarget>{data.Asset}</PackageTarget>

                        <PackagePercentage>
                          {(
                            (total * data.Proportion) /
                            transferUsdt(data.Asset, 1)
                          )?.toFixed(2)}
                        </PackagePercentage>
                      </PacakageDataContainer>
                    ))}
                  </PackageDataWrapper>
                </PacakageImgDataWrapper>

                <PacakageTotalWrapper>
                  <PackageTotalContainer>
                    <PacakageTotalName>Total</PacakageTotalName>
                    <PacakageTotal>{Number(total)?.toFixed(2)}</PacakageTotal>
                  </PackageTotalContainer>
                </PacakageTotalWrapper>
              </Package>
            </PackageContainer>
          </PackageWrapper>
        </ScrollContainer>
      </Container>
    </Wrapper>
  );
};

export default Preview;
